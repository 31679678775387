import i18n from '../i18n/config';

import ArticleBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/ArticleBreadcrumb';
import CategoryBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CategoryBreadcrumb';
import CompanyBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CompanyBreadcrumb';
import TopicBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/TopicBreadcrumb';
import TopicHeadBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/TopicHeadBreadcrumb';
import MediaBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/MediaBreadcrumb';

export const ROUTE_NAMES = {
    HOME: '/',
    ARTICLES_PAGE: 'straipsniai',
    ARTICLE_INNER: ':cardId',
    MEDIA_PAGE: 'pranesimai-spaudai',
    MEDIA_INNER: ':cardId',
    EU_PROJECTS: 'informacija/es-projektai',
    PAGE_NOT_FOUND: '*',
    COMPANY_CARD: 'imones-kortele/:companyId',
    ALL_CATEGORIES: '/imoniu-katalogas',
    BUSINESS_LIST_CHILD: 'imoniu-sarasas',
    BUSINESS_LIST: '/imoniu-sarasas',
    BUSINESS_LIST_CATEGORY: ':categoryId',
    BUSINESS_LIST_TOPIC: ':topicId',
    BUSINESS_LIST_SUBTOPIC: ':subtopicId',
};

export const routes = [
    {
        path: ROUTE_NAMES.HOME,
        breadcrumb: i18n.t('main_page'),
        disableGate: false,
    },
    {
        path: ROUTE_NAMES.EU_PROJECTS,
        breadcrumb: i18n.t('EU_projects'),
    },
    {
        path: ROUTE_NAMES.ALL_CATEGORIES,
        breadcrumb: i18n.t('all_categories'),
        children: [
            {
                path: ROUTE_NAMES.BUSINESS_LIST_CHILD,
                breadcrumb: i18n.t('companies_catalogue'),
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                        breadcrumb: CategoryBreadcrumb,
                        children: [
                            {
                                path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                                breadcrumb: TopicBreadcrumb,
                                children: [
                                    {
                                        path: ROUTE_NAMES.COMPANY_CARD,
                                        breadcrumb: CompanyBreadcrumb,
                                    },
                                    {
                                        path: ROUTE_NAMES.BUSINESS_LIST_SUBTOPIC,
                                        breadcrumb: TopicHeadBreadcrumb,
                                        children: [
                                            {
                                                path: ROUTE_NAMES.COMPANY_CARD,
                                                breadcrumb: CompanyBreadcrumb,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
        ],
    },
    {
        path: ROUTE_NAMES.PAGE_NOT_FOUND,
        breadcrumb: null,
    },
    {
        path: ROUTE_NAMES.COMPANY_CARD,
        breadcrumb: CompanyBreadcrumb,
    },
    {
        path: ROUTE_NAMES.ARTICLES_PAGE,
        breadcrumb: i18n.t('articles'),
        children: [
            {
                path: ROUTE_NAMES.ARTICLE_INNER,
                breadcrumb: ArticleBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_CHILD,
                        breadcrumb: i18n.t('companies_catalogue'),
                        children: [
                            {
                                path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                                breadcrumb: CategoryBreadcrumb,
                                children: [
                                    {
                                        path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                                        breadcrumb: TopicBreadcrumb,
                                        children: [
                                            {
                                                path: ROUTE_NAMES.COMPANY_CARD,
                                                breadcrumb: CompanyBreadcrumb,
                                            },
                                        ],
                                    },
                                    {
                                        path: ROUTE_NAMES.COMPANY_CARD,
                                        breadcrumb: CompanyBreadcrumb,
                                    },
                                ],
                            },
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
        ],
    },
    {
        path: ROUTE_NAMES.BUSINESS_LIST,
        breadcrumb: i18n.t('companies_catalogue'),
        children: [
            {
                path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                breadcrumb: CategoryBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                        breadcrumb: TopicBreadcrumb,
                        children: [
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                            {
                                path: ROUTE_NAMES.BUSINESS_LIST_SUBTOPIC,
                                breadcrumb: TopicHeadBreadcrumb,
                                children: [
                                    {
                                        path: ROUTE_NAMES.COMPANY_CARD,
                                        breadcrumb: CompanyBreadcrumb,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
    {
        path: ROUTE_NAMES.MEDIA_PAGE,
        breadcrumb: i18n.t('press_releases'),
        children: [
            {
                path: ROUTE_NAMES.MEDIA_INNER,
                breadcrumb: MediaBreadcrumb,
            },
        ],
    },
];
