import styled, { css } from 'styled-components';
import { ArticlesPreview } from '../../types/api';
import eastIcon from '@mui/icons-material/East';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import noImg from '../../assets/images/no_image.jpg';
import { SyntheticEvent } from 'react';

const ArticleCard = styled(Link)`
    text-decoration: none;
    box-shadow: 0px 4px 15px 0px rgba(33, 33, 33, 0.1);
    border-radius: 0 0 8px 8px;
    width: 100%;
    height: 100%;
    position: relative;
    &:hover {
        .active {
            font-weight: 600;
            transform: translateY(1px);
            &:after {
                content: '';
                height: 2px;
                display: flex;
                width: 52%;
                margin-left: 24%;
                position: relative;
                align-items: center;
                justify-content: center;
                top: 4px;
                background-color: ${(props) => props.theme.colors.green};
            }
        }
    }
`;
const Img = styled.img`
    border-radius: 8px 8px 0 0;
    height: 166px;
    width: 100%;
    object-fit: cover;
`;
const Category = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: ${props.theme.colors.green};
        margin-top: 4px;
    `}
`;
const Date = styled.div`
    ${(props) => css`
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: ${props.theme.colors.secondaryBlack};
        margin-top: 16px;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin: 4px 0 8px 0;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: ${props.theme.colors.secondaryBlack};
        margin-bottom: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-top: 18px;
        color: ${props.theme.colors.green};
        width: fit-content;
        position: absolute;
        cursor: pointer;
        bottom: 18px;
    `}
`;
const TextWrap = styled.div``;

const EastIcon = styled(eastIcon)`
    margin-left: 8px;
`;
const Wrap = styled.div`
    margin: 0 16px 50px;
    border-radius: 0 0 8px 8px;
`;
const TopicWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    margin-top: 12px;
`;

type Props = ArticlesPreview;

export const ArticlesYouMightLikeCard = (props: Props) => {
    const { t } = useTranslation();

    const onImageError = (e: SyntheticEvent<HTMLDivElement>) => {
        (e.target as HTMLImageElement).src = noImg;
    };

    return (
        <>
            <ArticleCard to={`/straipsniai/${props.id}`}>
                {props.banner && <Img src={props.banner} onError={onImageError} />}
                <Wrap>
                    <TopicWrap>
                        {props.topics.map((category, i) => {
                            return (
                                <Category key={i}>
                                    {category.name}
                                    {i + 1 !== props.topics.length && ','}&nbsp;
                                </Category>
                            );
                        })}
                    </TopicWrap>
                    <Date>{props.postDate}</Date>
                    <Header>{props.name}</Header>
                    <Text>{props.contentTeaser}</Text>
                    <Button>
                        <TextWrap className="active">{t('read')}</TextWrap>
                        <EastIcon fontSize="small" />
                    </Button>
                </Wrap>
            </ArticleCard>
        </>
    );
};
